<template>
    <transition-group name="fade" class="c-detail" tag="section">
        <template v-if="!loading">
            <section key="style-intro" class="c-detail-hero c-detail-hero--intro" v-if="style.image">
                <flickity class="c-detail-hero__slider" ref="flickity" :options="flickityOptions" v-if="style.image && style.images.slider[0]">
                    <observed-image class-name="c-detail-hero__slide" v-for="image in style.images.slider" :src="image.path" :key="image.path" />
                </flickity>

                <div class="c-detail-hero__content">
                    <h1>{{ style.name }}</h1>

                    <scroller target=".c-detail-banner"/>
                </div>
            </section>

            <section key="style-banner" class="c-detail-banner">
                <div class="c-detail-banner__content">
                    <template v-if="style.description.length">
                        <p v-html="style.description"></p>
                    </template>

                    <template v-else-if="style.descriptions.length === 0">
                        <inline-alert type="warning">Geen teksten gevonden voor {{ style.name }}</inline-alert>
                    </template>

                    <template v-else>
                        <inline-alert type="error">Er ging iets fout met het laden van de details</inline-alert>
                    </template>
                </div>

                <observed-image class-name="c-detail-banner__img u-cover" v-if="style.images.detail" :src="style.images.detail[0].path"/>
                <div class="c-detail-banner__img u-cover" v-else style="display: flex; align-items: center; justify-content: center;">
                    <inline-alert type="warning">Geen sfeer afbeelding gevonden voor {{ style.name }}</inline-alert>
                </div>
            </section>

            <section key="style-info" class="c-detail-info" v-if="style.descriptions.length > 0">
                <div class="c-detail-info__list">
                    <div class="c-detail-text" v-for="desc in style.descriptions" :key="desc.id">
                        <h4>{{ desc.header }}</h4>
                        <p v-html="desc.description"></p>
                    </div>
                </div>
            </section>

            <footer key="style-footer" class="c-detail-footer">
                <router-link to="/" class="o-c2a-link o-c2a-link--tint o-c2a-link--reverse">
                    <span class="o-c2a-link__text">Terug naar configuratie</span>
                    <span class="o-c2a-link__icon">
                        <span class="o-c2a-link__icon-circle"></span>
                    </span>
                </router-link>

                <a href="https://www.vsc.nl/projecten" target="_blank" class="o-c2a-link o-c2a-link--primary">
                    <span class="o-c2a-link__text">Bekijk referenties</span>
                    <span class="o-c2a-link__icon">
                        <span class="o-c2a-link__icon-circle"></span>
                    </span>
                </a>
            </footer>

        </template>
    </transition-group>
</template>

<script>
    import Flickity from 'vue-flickity';
    import InlineAlert from '../../components/InlineAlert.vue';
    import ObservedImage from '../../components/ObservedImage.vue';
    import Scroller from '../../components/Scroller.vue';
    import { detailsService } from '../../services';

    export default {
        components: {
            Scroller,
            Flickity,
            InlineAlert,
            ObservedImage,
        },
        data () {
            return {
                loading: true,
                style: {},
                flickityOptions: {
                    cellSelector: '.c-detail-hero__slide',
                    pageDots: false,
                },
            };
        },
        async mounted () {
            await this.getDetails();

            // rerender flickity else the slider is not initialsed
            this.$refs.flickity.rerender();
        },
        methods: {
            getDetails () {
                return detailsService
                    .getById('styles', this.$route.params.id)
                    .then((result) => {
                        this.loading = false;
                        this.style = result;
                    }).catch(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-detail-hero {
        display: grid;
        grid-template-columns: 7.5% 1fr 2fr 7.5%;
        grid-template-rows: 45vh 1fr;
        height: calc(100vh - #{rem(map_get($header-height, xs))} - #{rem(161)});
        position: relative;

        @include media-breakpoint-up(sm) {
            height: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
            grid-template-rows: 1fr 33vh;
        }

        @include media-breakpoint-between(md, lg) {
            grid-template-rows: 1fr 45vh;
        }

        @include media-breakpoint-up(xl) {
            grid-template-rows: 1fr rem(400);
        }

        &:before {
            @include fill;
            @include gradient-y(rgba(black, .0), rgba(black, .58));
            content: "";
            display: block;
            pointer-events: none;
            z-index: 1;
        }

        &__slider,
        &__img {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
        }

        &__slide {
            height: calc(100vh - #{rem(map_get($header-height, xs))} - #{rem(161)});
            width: 100%;

            @include media-breakpoint-up(sm) {
                height: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
            }
        }

        &__content {
            grid-row: 2;
            grid-column: 2 / -2;
            color: $white;
            z-index: 1;

            h1,
            .alpha {
                color: inherit;
                text-transform: none;
            }
        }
    }

    .c-detail-banner {
        background-color: $gray-light;
        display: grid;
        grid-template-rows: 1fr 1fr;

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1.5fr 1fr;
            grid-template-rows: auto;
        }

        &__img {
            grid-row: 1;
        }

        &__content {
            background-color: $gray-lighter;
            grid-row: 2;
            padding: rem(50) 10%;

            *:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                grid-row: 1;
                padding: 15%;
            }
        }
    }

    .c-detail-info {
        background-color: $white;
        display: grid;
        grid-template-columns: 10% 1fr 10%;
        padding: rem(50) 0 rem(150);

        @include media-breakpoint-up(sm) {
            padding: 7.5% 0;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 20% 1fr 20%;
        }

        &__list {
            display: grid;
            grid-gap: rem(50);
            grid-column: 2;

            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .c-detail-footer {
        @include fixed(left 0 right 0 bottom 0);
        background-color: $white;
        display: grid;
        grid-gap: rem(7);
        grid-template-columns: 1fr;
        border: rem(7) solid $white;
        z-index: 200;

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1fr 1fr;
        }
    }
</style>
